/* eslint-disable no-use-before-define */
/* eslint-disable no-empty-function */

import FrameWorker from "./FrameWorker.worker.js";
import GifWorker from "./GifWorker.worker.js";

const SIZE = 112;
const CUTOFF = 0.7;
const NUM_WORKERS = 1;

const makeGif = function makeGif(opts = { delay: 0.05 }) {
  const delay = opts.delay * 100;
  const canvasGif = document.createElement("canvas");
  canvasGif.width = SIZE;
  canvasGif.height = SIZE;
  const ctx = canvasGif.getContext("2d");
  const frames = [];
  let onRenderCompleteCallback;
  const frameWorkers = [];
  const availableFrameWorkers = [];
  const gifWorker = new GifWorker();

  // @ts-ignore
  window.__makeEmojiAllWorkers.push(gifWorker);

  for (let i = 0; i < NUM_WORKERS; i++) {
    const w = new FrameWorker();
    frameWorkers.push(w);
    availableFrameWorkers.push(w);
    // @ts-ignore
    window.__makeEmojiAllWorkers.push(w);
  }

  function getFrameWorker() {
    // Return a worker for processing a frame
    if (availableFrameWorkers.length === 0) throw "No frameWorkers left!";
    return availableFrameWorkers.pop();
  }

  function freeWorker(worker) {
    // Restore a worker to the pool
    availableFrameWorkers.push(worker);
  }

  // Faster/closurized bufferToString function
  // (caching the String.fromCharCode values)
  const bufferToString = (function () {
    const byteMap = [];
    for (let i = 0; i < 256; i++) {
      byteMap[i] = String.fromCharCode(i);
    }

    return function (buffer) {
      const numberValues = buffer.length;
      let str = "";
      for (let i = 0; i < numberValues; i++) {
        str += byteMap[buffer[i]];
      }
      return str;
    };
  })();

  function generateGIF(frames, callback) {
    gifWorker.onmessage = function (ev) {
      const data = ev.data;
      callback(data);
    };
    gifWorker.postMessage({ frames, size: SIZE, delay });
  }

  function processFrame(position) {
    const frame = frames[position];

    if (frame.beingProcessed || frame.done) {
      console.error("Frame already being processed or done!", frame.position);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      onFrameFinished();
      return;
    }

    frame.beingProcessed = true;

    const frameWorker = getFrameWorker();

    frameWorker.onmessage = function (ev) {
      const data = ev.data;
      delete frame.data; // Delete original data, and free memory
      // TODO grrr... HACK for object -> Array
      frame.pixels = Array.prototype.slice.call(data.pixels);
      frame.palette = Array.prototype.slice.call(data.palette);
      frame.transparencyIndex = data.transparencyIndex;
      frame.done = true;
      frame.beingProcessed = false;
      freeWorker(frameWorker);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      onFrameFinished();
    };

    frameWorker.postMessage(frame);
  }

  function processNextFrame() {
    let position = -1;
    for (let i = 0; i < frames.length; i++) {
      const frame = frames[i];
      if (!frame.done && !frame.beingProcessed) {
        position = i;
        break;
      }
    }

    if (position >= 0) processFrame(position);
  }

  function onFrameFinished() {
    // GIF not written until done with all frames
    const allDone = frames.every(function (frame) {
      return !frame.beingProcessed && frame.done;
    });

    if (allDone) {
      generateGIF(frames, onRenderCompleteCallback);
    } else {
      setTimeout(processNextFrame, 1);
    }
  }

  function startRendering(completeCallback) {
    onRenderCompleteCallback = completeCallback;
    for (let i = 0; i < NUM_WORKERS && i < frames.length; i++) {
      processFrame(i);
    }
  }

  this.addFrame = function (element, options = {}) {
    ctx.clearRect(0, 0, SIZE, SIZE);
    ctx.drawImage(element, 0, 0, SIZE, SIZE);
    const imageData = ctx.getImageData(0, 0, SIZE, SIZE);
    const imageDataArray = new Uint8Array(imageData.data);
    frames.push({
      data: imageDataArray,
      width: SIZE,
      height: SIZE,
      palette: undefined,
      dithering: undefined,
      disposal: 2,
      transparencyCutOff: CUTOFF,
      done: false,
      beingProcessed: false,
      position: frames.length
    });
  };

  this.getBase64GIF = function (completeCallback) {
    const onRenderComplete = function (buffer) {
      const str = bufferToString(buffer);
      const gif = "data:image/gif;base64," + btoa(str);
      completeCallback(gif);
    };
    startRendering(onRenderComplete);
  };

  this.destroy = function () {
    frameWorkers.forEach(w => w.terminate());
    gifWorker.terminate();
  };
};

export { makeGif };
