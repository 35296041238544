type translation = [number, number];
type doubleTranslation = [[number, number], [number, number]];

export const partyTranslations: translation[] = [
  [0, 10],
  [10, 8],
  [15, 3],
  [10, -3],
  [3, -8],
  [0, -10],
  [-3, -8],
  [-10, -3],
  [-15, 0],
  [-8, 8]
];

export const shakingTranslations: translation[] = [
  [6, 0],
  [4, 0],
  [-2, 0],
  [4, 0],
  [6, 0],
  [2, 0],
  [-2, 0],
  [-6, 0],
  [0, 0],
  [2, 0]
];

export const shakingAllTranslations: translation[] = [
  [8, 4],
  [4, -4],
  [-3, 0],
  [4, 8],
  [7, 4],
  [2, 0],
  [-2, 6],
  [-8, -5],
  [0, 4],
  [2, 0]
];

export const bounceTranslations: translation[] = [
  [0, 6],
  [0, 4],
  [0, -2],
  [0, 4],
  [0, 6],
  [0, 2],
  [0, -2],
  [0, -6],
  [0, 0],
  [0, 2]
];

export const popInOutBottomTranslations: translation[] = [
  [0, 100],
  [0, 66],
  [0, 33],
  [0, 0],
  [0, 0],
  [0, 0],
  [0, 0],
  [0, 33],
  [0, 66],
  [0, 100]
];

export const popInOutTopTranslations: translation[] = [
  [0, -100],
  [0, -66],
  [0, -33],
  [0, 0],
  [0, 0],
  [0, 0],
  [0, 0],
  [0, -33],
  [0, -66],
  [0, -100]
];

export const popInOutLeftTranslations: translation[] = [
  [100, 0],
  [66, 0],
  [33, 0],
  [0, 0],
  [0, 0],
  [0, 0],
  [0, 0],
  [33, 0],
  [66, 0],
  [100, 0]
];

export const popInOutRightTranslations: translation[] = [
  [-100, 0],
  [-66, 0],
  [-33, 0],
  [-0, 0],
  [-0, 0],
  [-0, 0],
  [-0, 0],
  [-33, 0],
  [-66, 0],
  [-100, 0]
];

export const scrollLeftDoubleTranslations: doubleTranslation[] = [
  [
    [0, 0],
    [100, 0]
  ],
  [
    [-10, 0],
    [90, 0]
  ],
  [
    [-20, 0],
    [80, 0]
  ],
  [
    [-30, 0],
    [70, 0]
  ],
  [
    [-40, 0],
    [60, 0]
  ],
  [
    [-50, 0],
    [50, 0]
  ],
  [
    [-60, 0],
    [40, 0]
  ],
  [
    [-70, 0],
    [30, 0]
  ],
  [
    [-80, 0],
    [20, 0]
  ],
  [
    [-90, 0],
    [10, 0]
  ]
];

export const scrollRightDoubleTranslations: doubleTranslation[] = [
  [
    [0, 0],
    [-100, 0]
  ],
  [
    [10, 0],
    [-90, 0]
  ],
  [
    [20, 0],
    [-80, 0]
  ],
  [
    [30, 0],
    [-70, 0]
  ],
  [
    [40, 0],
    [-60, 0]
  ],
  [
    [50, 0],
    [-50, 0]
  ],
  [
    [60, 0],
    [-40, 0]
  ],
  [
    [70, 0],
    [-30, 0]
  ],
  [
    [80, 0],
    [-20, 0]
  ],
  [
    [90, 0],
    [-10, 0]
  ]
];

export const scrollUpDoubleTranslations: doubleTranslation[] = [
  [
    [0, 0],
    [0, 100]
  ],
  [
    [0, -10],
    [0, 90]
  ],
  [
    [0, -20],
    [0, 80]
  ],
  [
    [0, -30],
    [0, 70]
  ],
  [
    [0, -40],
    [0, 60]
  ],
  [
    [0, -50],
    [0, 50]
  ],
  [
    [0, -60],
    [0, 40]
  ],
  [
    [0, -70],
    [0, 30]
  ],
  [
    [0, -80],
    [0, 20]
  ],
  [
    [0, -90],
    [0, 10]
  ]
];

export const scrollDownDoubleTranslations: doubleTranslation[] = [
  [
    [0, 0],
    [0, -100]
  ],
  [
    [0, 10],
    [0, -90]
  ],
  [
    [0, 20],
    [0, -80]
  ],
  [
    [0, 30],
    [0, -70]
  ],
  [
    [0, 40],
    [0, -60]
  ],
  [
    [0, 50],
    [0, -50]
  ],
  [
    [0, 60],
    [0, -40]
  ],
  [
    [0, 70],
    [0, -30]
  ],
  [
    [0, 80],
    [0, -20]
  ],
  [
    [0, 90],
    [0, -10]
  ]
];

export const zoomScalings: number[] = [
  0.75,
  0.87,
  1,
  1.25,
  1.5,
  1.5,
  1.25,
  1,
  0.87,
  0.75
];

export const zoomInScalings: number[] = [
  1,
  1.25,
  1.5,
  1.75,
  2,
  2,
  1.75,
  1.5,
  1.25,
  1
];

export const zoomOutScalings: number[] = [
  1,
  0.87,
  0.74,
  0.62,
  0.5,
  0.5,
  0.62,
  0.74,
  0.87,
  1
];

export const zoomInInfiniteScalings: number[] = [
  0.6,
  0.8,
  1,
  1.2,
  1.4,
  1.6,
  1.8,
  2,
  2.2,
  2.4
];

export const zoomOutInfiniteScalings: number[] = [
  2.4,
  2.2,
  2,
  1.8,
  1.6,
  1.4,
  1.2,
  1,
  0.8,
  0.6
];

export const tiltRotations: number[] = [
  -1,
  -0.8,
  -0.3,
  0.3,
  0.8,
  1,
  0.8,
  0.3,
  -0.3,
  -0.8,
  -1
];

export const flipTranslations: translation[] = [
  [0, 0],
  [10, -2],
  [20, -4],
  [30, -2],
  [40, 0],
  [50, 2],
  [60, 4],
  [70, 2],
  [80, 0],
  [90, -2],
  [100, -4],
  [90, -2],
  [80, 0],
  [70, 2],
  [60, 4],
  [50, 2],
  [40, 0],
  [30, -2],
  [20, -4],
  [10, -2],
  [0, 0]
];

export const flipScalings: number[] = [
  0.6,
  0.8,
  1,
  1.2,
  1.4,
  1.6,
  1.8,
  2,
  2.2,
  2.4
];
