import contrastFilter from "../filters/contrast";

const headBob = [
  [-0.03, 0],
  [0, -0.01],
  [0.03, 0],
  [0.015, 0.01],
  [0, 0.01],
  [-0.03, 0],
  [0, -0.01],
  [0.03, 0],
  [0.015, 0.01],
  [0, 0.01]
];

const getImg = async (i: number) => {
  const i2 = i > 4 ? i - 5 : i;
  const fetched = await fetch(`./src/assets/frames/pet/frame_${i2}_delay-0.06s.png`);
  const blob: Blob = await fetched.blob();
  const dataUrl = URL.createObjectURL(blob);
  return await new Promise((resolve, reject) => {
    const newImg = new Image();
    newImg.onload = () => resolve(newImg);
    newImg.src = dataUrl;
  });
};

const squeeze = [
  [0.95, 1],
  [0.975, 1],
  [1, 0.95],
  [1.05, 0.9],
  [0.975, 0.95],
  [0.95, 1],
  [0.975, 1],
  [1, 0.95],
  [1.05, 0.9],
  [0.975, 0.95],
];

const createPetFrame = async (
  ctx: CanvasRenderingContext2D,
  img: HTMLImageElement,
  i: number,
  width: number,
  height: number,
  contrast: number
) => {
  const petImg = (await getImg(i)) as HTMLImageElement;
  const [headBobY, headBobX] = headBob[i];
  const [squeezeY, squeezeX] = squeeze[i];
  ctx.translate(width / 2, height / 2);
  ctx.drawImage(
    img,
    -width / 2 + width * 0.2,
    -height / 2 + height * 0.2 + height * (headBobY),
    width * 0.85 * squeezeX,
    height * 0.85 * squeezeY
  );
  ctx.drawImage(petImg, -width / 2 - 2, -height / 2 - 6);
};

export default createPetFrame;
