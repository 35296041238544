import brightnessFilter from "./filters/brightness";
import contrastFilter from "./filters/contrast";
import hueRotateFilter from "./filters/hueRotate";
import saturateFilter from "./filters/saturate";
import sepiaFilter from "./filters/sepia";
import {
  partyTranslations,
  shakingTranslations,
  shakingAllTranslations,
  bounceTranslations,
  popInOutBottomTranslations,
  popInOutTopTranslations,
  popInOutLeftTranslations,
  popInOutRightTranslations,
  scrollLeftDoubleTranslations,
  scrollRightDoubleTranslations,
  scrollUpDoubleTranslations,
  scrollDownDoubleTranslations,
  zoomScalings,
  zoomInScalings,
  zoomOutScalings,
  zoomInInfiniteScalings,
  zoomOutInfiniteScalings,
  tiltRotations,
  flipTranslations,
  flipScalings
} from "./translations";
import createFireFrame from "./createFrames/createFireFrame";
import createBurnFrame from "./createFrames/createBurnFrame";
import createPetFrame from "./createFrames/createPetFrame";
import createDealWithItFrame from "./createFrames/createDealWithItFrame";
import createVibingCatFrame from "./createFrames/createVibingCatFrame";
import createNyanCatFrame from "./createFrames/createNyanCatFrame";
import createPartyParrotFrame from "./createFrames/createPartyParrotFrame";
import createPartyBlobFrame from "./createFrames/createPartyBlobFrame";
import createGunFrame from "./createFrames/createGunFrame";
import createJammiesFrame from "./createFrames/createJammiesFrame";

const contrast = 120;
const saturate = 250;
const frameCount = 10;
const width = 112;
const height = 112;

// All hardcoded translation values assume a 100x100 square
// Adjust transformation values for custom width
const ASSUMED_WIDTH = 100;
const adjust = (n: number) => (n / ASSUMED_WIDTH) * width;

const rotate = (
  ctx: CanvasRenderingContext2D,
  img: HTMLImageElement,
  i: number,
  rotations: number[]
) => {
  const angle = (360 / frameCount) * rotations[i];
  const deg = (angle * Math.PI) / 180;
  ctx.translate(width / 2, height / 2);
  ctx.rotate(deg);
  ctx.drawImage(img, -width / 2, -height / 2);
};

const scale = (
  ctx: CanvasRenderingContext2D,
  img: HTMLImageElement,
  i: number,
  scalings: number[]
) => {
  const s = scalings[i];
  ctx.translate(width / 2, height / 2);
  ctx.drawImage(
    img,
    (width - width * s) / 2 - width / 2,
    (height - height * s) / 2 - height / 2,
    width * s,
    height * s
  );
};

type translation = [number, number];
const translate = (
  ctx: CanvasRenderingContext2D,
  img: HTMLImageElement,
  i: number,
  translations: translation[]
) => {
  const [x, y] = translations[i];
  ctx.translate(width / 2, height / 2);
  ctx.translate(adjust(x), adjust(y));
  ctx.drawImage(img, -width / 2, -height / 2);
};

type doubleTranslation = [[number, number], [number, number]];
const doubleTranslate = (
  ctx: CanvasRenderingContext2D,
  img: HTMLImageElement,
  i: number,
  doubleTranslations: doubleTranslation[]
) => {
  const [[x1, y1], [x2, y2]] = doubleTranslations[i];
  ctx.translate(width / 2, height / 2);
  ctx.drawImage(img, adjust(x1) + -width / 2, adjust(y1) + -height / 2);
  ctx.drawImage(img, adjust(x2) + -width / 2, adjust(y2) + -height / 2);
};

const colorNumbers = {
  red: 305,
  orange: 320,
  yellow: 0,
  green: 50,
  blue: 150,
  purple: 200
};

const createFilterColor = (ctx, filterColor) => {
  sepiaFilter(ctx, 100);
  hueRotateFilter(ctx, colorNumbers[filterColor]);
  saturateFilter(ctx, saturate * 2.5);
};

const applyFilters = (
  ctx: CanvasRenderingContext2D,
  i: number,
  filterColor: string,
  filterBrightness: number,
  filterContrast: number,
  filterSaturation: number
) => {
  if (filterColor === "rainbow") {
    const angle = (360 / frameCount) * i;
    const hdeg = 9 * angle;
    hueRotateFilter(ctx, hdeg);
  }
  if (filterColor === "flat-rainbow") {
    createFilterColor(ctx, filterColor);
    const angle = (360 / frameCount) * i;
    const hdeg = 9 * angle;
    hueRotateFilter(ctx, hdeg);
  }
  if (colorNumbers.hasOwnProperty(filterColor)) {
    createFilterColor(ctx, filterColor);
  }
  const frameBrightness = 100 + (filterBrightness - 50);
  brightnessFilter(ctx, frameBrightness);

  const frameContrast = 100 + (filterContrast - 50);
  contrastFilter(ctx, frameContrast);

  let frameSaturation = 100 + (filterSaturation - 50) * 2.5;
  if (filterColor === "rainbow") frameSaturation = frameSaturation + 100;
  saturateFilter(ctx, frameSaturation);
};

export default {
  none: (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    ctx.translate(width / 2, height / 2);
    ctx.drawImage(img, -width / 2, -height / 2);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  spin: (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    const angle = (360 / frameCount) * i;
    const deg = (angle * Math.PI) / 180;
    ctx.translate(width / 2, height / 2);
    ctx.rotate(deg);
    ctx.drawImage(img, -width / 2, -height / 2);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  party: (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    const [x, y] = partyTranslations[i];
    ctx.translate(width / 2, height / 2);
    ctx.translate(adjust(x), adjust(y));
    ctx.drawImage(img, -width / 2, -height / 2);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  shaking: (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    const [x, y] = shakingTranslations[i];
    ctx.translate(width / 2, height / 2);
    ctx.translate(adjust(x), adjust(y));
    ctx.drawImage(img, -width / 2, -height / 2);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  shakingAll: (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    const [x, y] = shakingAllTranslations[i];
    ctx.translate(width / 2, height / 2);
    ctx.translate(adjust(x), adjust(y));
    ctx.drawImage(img, -width / 2, -height / 2);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  bounce: (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    translate(ctx, img, i, bounceTranslations);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  popInOutBottom: (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    translate(ctx, img, i, popInOutBottomTranslations);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  popInOutTop: (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    translate(ctx, img, i, popInOutTopTranslations);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  popInOutLeft: (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    translate(ctx, img, i, popInOutLeftTranslations);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  popInOutRight: (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    translate(ctx, img, i, popInOutRightTranslations);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  flip: (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    ctx.translate(width / 2, height / 2);
    if (i > 4) ctx.scale(-1, 1);
    ctx.drawImage(img, -width / 2, -height / 2);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  jam: (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    const squeeze = [
      [0.95, 0],
      [0.975, 2],
      [1, 4],
      [1.05, 6],
      [0.975, 8],
      [0.95, 0],
      [0.975, 2],
      [1, 4],
      [1.05, 6],
      [0.975, 8]
    ];
    const [squeezeY, squeezeAdd] = squeeze[i];
    ctx.translate(width / 2, height / 2);
    if (i > 4) ctx.scale(-1, 1);
    ctx.drawImage(
      img,
      -width / 2,
      -height / 2 + squeezeAdd * 2 - 10,
      width,
      height * (squeezeY / 2 + 0.5)
    );
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  scrollLeft: (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    doubleTranslate(ctx, img, i, scrollLeftDoubleTranslations);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  scrollRight: (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    doubleTranslate(ctx, img, i, scrollRightDoubleTranslations);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  scrollUp: (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    doubleTranslate(ctx, img, i, scrollUpDoubleTranslations);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  scrollDown: (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    doubleTranslate(ctx, img, i, scrollDownDoubleTranslations);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  zoom: (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    scale(ctx, img, i, zoomScalings);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  zoomIn: (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    scale(ctx, img, i, zoomInScalings);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  zoomOut: (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    scale(ctx, img, i, zoomOutScalings);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  zoomInInfinite: (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    scale(ctx, img, i, zoomInInfiniteScalings);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  zoomOutInfinite: (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    scale(ctx, img, i, zoomOutInfiniteScalings);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  tilt: (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    rotate(ctx, img, i, tiltRotations);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  fire: async (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    await createFireFrame(ctx, img, i, width, height, contrast);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  burn: async (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    await createBurnFrame(ctx, img, i, width, height, contrast);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  pet: async (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    await createPetFrame(ctx, img, i, width, height, contrast);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  dealWithIt: async (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    await createDealWithItFrame(ctx, img, i, width, height, contrast);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  vibingCat: async (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    await createVibingCatFrame(ctx, img, i, width, height, contrast);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  nyanCat: async (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    await createNyanCatFrame(ctx, img, i, width, height);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  partyParrot: async (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    await createPartyParrotFrame(ctx, img, i, width, height);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  partyBlob: async (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    await createPartyBlobFrame(ctx, img, i, width, height);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  gun: async (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    await createGunFrame(ctx, img, i, width, height);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  jammies: async (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness: number,
    filterContrast: number,
    filterSaturation: number
  ) => {
    await createJammiesFrame(ctx, img, i, width, height);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  // cube3d: async (
  //   ctx: CanvasRenderingContext2D,
  //   img: HTMLImageElement,
  //   i: number,
  //   filterColor: string,
  //   filterBrightness,
  //   filterContrast,
  //   filterSaturation
  // ) => {
  //   const rotation = ((2 * Math.PI) / 10) * i; // Divide full rotation into 10 parts

  //   ctx.translate(width / 2, height / 2);
  //   ctx.rotate(rotation);
  //   ctx.drawImage(img, -width / 2, -height / 2, width, height);
  //   ctx.setTransform(1, 0, 0, 1, 0, 0); // Reset transformation matrix

  //   applyFilters(
  //     ctx,
  //     i,
  //     filterColor,
  //     filterBrightness,
  //     filterContrast,
  //     filterSaturation
  //   );
  // },
  bounceJelly: async (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness,
    filterContrast,
    filterSaturation
  ) => {
    const bounce = [
      [1, 0], // Start at the original position
      [1.02, -2], // Anticipation: Move slightly upwards before the jump
      [1.04, -5], // Action: Jump upwards
      [1, -7], // Overshoot: Go a bit higher than the highest point
      [1.04, -5], // Reaction: Fall back to the highest point
      [0.98, 7], // Anticipation: Move slightly downwards before the fall
      [0.96, 10], // Action: Fall downwards
      [0.94, 12], // Overshoot: Go a bit lower than the original position
      [0.96, 10], // Reaction: Rise back to the original position
      [1, 0] // Settle back to the original position
    ];
    const [bounceY, bounceAdd] = bounce[i % bounce.length];
    ctx.translate(width / 2, height / 2);
    ctx.scale(1, bounceY);
    ctx.drawImage(img, -width / 2, -height / 2 + bounceAdd, width, height);
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  },
  bounceJellyBig: async (
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    i: number,
    filterColor: string,
    filterBrightness,
    filterContrast,
    filterSaturation
  ) => {
    const bounce = [
      [1, 0], // Squash and Stretch: Start at the original position
      [1.1, -10], // Anticipation: Move upwards before the jump
      [1.2, -20], // Action: Jump upwards
      [1, -30], // Overshoot: Go higher than the highest point
      [1.2, -20], // Reaction: Fall back to the highest point
      [0.9, 30], // Anticipation: Move downwards before the fall
      [0.8, 50], // Action: Fall downwards
      [0.7, 60], // Overshoot: Go lower than the original position
      [0.8, 50], // Reaction: Rise back to the original position
      [1, 0] // Settle back to the original position
    ];
    const [bounceY, bounceAdd] = bounce[i % bounce.length];
    ctx.translate(width / 2, height / 2);
    ctx.scale(1, bounceY);
    // Constrain the image within the canvas bounding box
    const constrainedBounceAdd = Math.max(
      Math.min(bounceAdd, height / 2),
      -height / 2
    );
    ctx.drawImage(
      img,
      -width / 2,
      -height / 2 + constrainedBounceAdd,
      width,
      height
    );
    applyFilters(
      ctx,
      i,
      filterColor,
      filterBrightness,
      filterContrast,
      filterSaturation
    );
  }
  // partyStayAtHome: async (
  //   ctx: CanvasRenderingContext2D,
  //   img: HTMLImageElement,
  //   i: number,
  //   filterColor: string,
  //   filterBrightness: number,
  //   filterContrast: number,
  //   filterSaturation: number
  // ) => {
  //   await createPartyStayAtHomeFrame(
  //     ctx,
  //     img,
  //     i,
  //     width,
  //     height,
  //     contrast,
  //     saturate,
  //     frameCount,
  //     partyTranslations
  //   );
  // }
};
