import React, {
  FunctionComponent,
  useState,
  Suspense,
  useEffect,
  ChangeEvent
} from "react";
import fixOrientation from "fix-orientation";
import useResizedImage from "./useResizedImage";
import {
  EditArea,
  EditorArea,
  EditorWrap,
  LoadingText,
  ImageInput,
  TestImageArea,
  TestImageLink,
  EmojiArea,
  EmailArea,
  Header,
  Subheader,
  Text,
  // Spaces,
  UploadedImage
} from "./styled";
import EmojiPanel from "../EmojiPanel/index";

export const blank = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=`;

const imageFilenames = [
  "1password.png",
  "asana.png",
  "adidas.png",
  "carta.png",
  "chewy.png",
  "datadog.png",
  // "flocksafety.png",
  // "forter.png",
  // "fullstory.png",
  "github.png",
  "gitlab.png",
  "huggingface.png",
  "kakao.png",
  "lotte.png",
  "mckinsey.png",
  "meta.png",
  "mongodb.png",
  "roku.png",
  "salesforce.png",
  "square.png",
  "squarespace.jpg",
  "unity.png",
  "x.png",
  // "zoominfo.png",
  // "checkoutcom.png",
  // "inngest.png",
  "scaleai.png",
  "shopify.png",
  "zapier.png"
];

// randomize order of imageFilenames with Fisher-Yates shuffle
for (let i = imageFilenames.length - 1; i > 0; i--) {
  const j = Math.floor(Math.random() * (i + 1));
  [imageFilenames[i], imageFilenames[j]] = [
    imageFilenames[j],
    imageFilenames[i]
  ];
}

const testImageData: { url: string; alt: string }[] = [
  { url: "favicon.png", alt: "An excited-looking white cat" },
  { url: "peepo.png", alt: "The Peepo meme" },
  { url: "doge.png", alt: "The Shiba doge meme" },
  { url: "troll.png", alt: "The troll face meme" },
  { url: "stonks.png", alt: "The stonks meme" },
  { url: "thinking-face.png", alt: "The thonk meme" },
  {
    url: "mild_panic.png",
    alt: "An emoji face with wide eyes and a bead of sweat"
  },
  {
    url: "laugh-cry.png",
    alt: "An emoji face with one half laughing and the other half crying"
  },
  { url: "f.png", alt: "The F key" },
  { url: "pogging.png", alt: "The pogging meme" },
  { url: "dwayne.png", alt: "The Rock raising an eyebrow" },
  { url: "kitty-blush.png", alt: "The kitty blush meme" },
  { url: "kekw.png", alt: "The kekw meme" },
  { url: "duck.png", alt: "A stuffed duck with a knife" },
  { url: "bruh.png", alt: "The bruh meme" },
  { url: "gigachad.png", alt: "The gigachad meme" },
  { url: "mega-flushed.png", alt: "A flushed emoji face" },
  { url: "monkas.png", alt: "The MonkasS meme" },
  { url: "rip.png", alt: "A gravestone with R.I.P. on it" },
  { url: "doritos.png", alt: "A Dorito" },
  { url: "pjsalt.png", alt: "The PJSalt meme" },
  { url: "smorc.png", alt: "The Smorc meme" },
  { url: "the-illuminati.png", alt: "The Illuminati symbol" },
  { url: "poggers.png", alt: "The poggers meme" },
  { url: "ssssss.png", alt: "A creeper from Minecraft" },
  { url: "biblethump.png", alt: "The BibleThump meme" },
  { url: "crythumbsup.png", alt: "A cat crying and giving a thumbs up" },
  { url: "notlikethis.png", alt: "The NotLikeThis meme" },
  { url: "pepe-yes.png", alt: "The PepeYes meme" },
  { url: "pepe-no.png", alt: "The PepeNo meme" },
  { url: "uwucat.png", alt: "A cat with a uwu face" },
  { url: "honkler.png", alt: "The Honkler meme" },
  { url: "cryingman.jpg", alt: "A crying man" },
  { url: "catscream.png", alt: "A cat screaming" },
  { url: "laughinghard.jpg", alt: "a man with glasses laughing hard" }
];

const emojiSpeeds = {
  ["pet"]: {
    slow: 0.06,
    normal: 0.045,
    fast: 0.02
  },
  ["jam"]: {
    slow: 0.09,
    normal: 0.06,
    fast: 0.03
  },
  ["jammies"]: {
    slow: 0.09,
    normal: 0.06,
    fast: 0.03
  },
  ["gun"]: {
    slow: 0.06,
    normal: 0.04,
    fast: 0.02
  },
  ["nyan-cat"]: {
    slow: 0.09,
    normal: 0.06,
    fast: 0.03
  },
  ["party-parrot"]: {
    slow: 0.07,
    normal: 0.035,
    fast: 0.02
  },
  ["party-blob"]: {
    slow: 0.07,
    normal: 0.035,
    fast: 0.02
  },
  ["deal-with-it"]: {
    slow: 0.133,
    normal: 0.1,
    fast: 0.066
  },
  ["cat-jam"]: {
    slow: 0.1,
    normal: 0.07,
    fast: 0.04
  },
  ["burn"]: {
    slow: 0.133,
    normal: 0.1,
    fast: 0.066
  },
  ["on-fire"]: {
    slow: 0.133,
    normal: 0.1,
    fast: 0.066
  },
  ["shake"]: {
    slow: 0.05,
    normal: 0.035,
    fast: 0.02
  },
  ["tilt"]: {
    slow: 0.08,
    normal: 0.06,
    fast: 0.04
  },
  ["zoom"]: {
    slow: 0.08,
    normal: 0.06,
    fast: 0.04
  },
  ["zoom-close"]: {
    slow: 0.09,
    normal: 0.07,
    fast: 0.05
  },
  ["zoom-far"]: {
    slow: 0.09,
    normal: 0.07,
    fast: 0.05
  },
  ["zoom-in"]: {
    slow: 0.08,
    normal: 0.06,
    fast: 0.04
  },
  ["zoom-out"]: {
    slow: 0.08,
    normal: 0.06,
    fast: 0.04
  },
  ["party"]: {
    slow: 0.08,
    normal: 0.05,
    fast: 0.02
  },
  ["spin"]: {
    slow: 0.1,
    normal: 0.08,
    fast: 0.05
  },
  ["intensifies"]: {
    slow: 0.05,
    normal: 0.03,
    fast: 0.02
  },
  ["excited"]: {
    slow: 0.1,
    normal: 0.07,
    fast: 0.03
  },
  ["flip"]: {
    slow: 0.12,
    normal: 0.08,
    fast: 0.03
  },
  ["slide"]: {
    slow: 0.12,
    normal: 0.08,
    fast: 0.04
  },
  ["scroll"]: {
    slow: 0.07,
    normal: 0.04,
    fast: 0.02
  },
  ["none"]: {
    slow: 0.1,
    normal: 0.05,
    fast: 0.02
  },
  ["bounce"]: {
    slow: 0.07,
    normal: 0.04,
    fast: 0.02
  },
  ["mega-bounce"]: {
    slow: 0.07,
    normal: 0.04,
    fast: 0.02
  }
  // ["3d-rotate"]: {
  //   slow: 0.07,
  //   normal: 0.04,
  //   fast: 0.02
  // }
};

const emojiStyles = [
  ["pet", "pet", 0.1],
  ["party-parrot", "partyParrot", 0.035],
  ["party-blob", "partyBlob", 0.035],
  ["jammies", "jammies"],
  ["nyan-cat", "nyanCat", 0.06],
  ["shake", "shakingAll", 0.04],
  ["party", "party", 0.05],
  ["jam", "jam", 0.1],
  ["spin", "spin", 0.08],
  ["gun", "gun", 0.1],
  ["deal-with-it", "dealWithIt", 0.1],
  ["cat-jam", "vibingCat", 0.07],
  ["burn", "burn", 0.1],
  ["on-fire", "fire", 0.1],
  ["intensifies", "shaking", 0.02],
  ["excited", "bounce", 0.07],
  ["flip", "flip", 0.1],
  [
    "slide",
    {
      right: "popInOutRight",
      up: "popInOutBottom",
      down: "popInOutTop",
      left: "popInOutLeft"
    },
    0.1
  ],
  [
    "scroll",
    {
      right: "scrollRight",
      up: "scrollUp",
      down: "scrollDown",
      left: "scrollLeft"
    },
    0.06
  ],
  ["tilt", "tilt", 0.06],
  ["zoom", "zoom", 0.06],
  ["zoom-close", "zoomIn", 0.07],
  ["zoom-far", "zoomOut", 0.07],
  ["zoom-in", "zoomInInfinite", 0.06],
  ["zoom-out", "zoomOutInfinite", 0.06],
  ["bounce", "bounceJelly", 0.1],
  ["mega-bounce", "bounceJellyBig", 0.1],
  // ["3d-rotate", "cube3d", 0.1],
  ["none", "none", 0]
];

const rotateExif = async (uri: string) => {
  return new Promise(resolve => {
    fixOrientation(uri, { image: false }, (fixedUri: string) => {
      resolve(fixedUri);
    });
  });
};

const getImageDataUri = async (uri: string) => {
  const fetched: Response = await fetch(uri);
  const blob: Blob = await fetched.blob();
  const dataUri: string = await new Promise(resolve => {
    const reader: FileReader = new FileReader();
    reader.onload = () => {
      const result: unknown = reader.result;
      if (typeof result === "string") resolve(result);
    };
    reader.readAsDataURL(blob);
  });
  return dataUri;
};

const terminateAllWorkers = () => {
  // @ts-ignore
  if (!window.__makeEmojiAllWorkers) return;
  // @ts-ignore
  window.__makeEmojiAllWorkers.forEach(worker => {
    worker.terminate();
  });
  // @ts-ignore
  window.__makeEmojiAllWorkers = [];
};

export const App: FunctionComponent = () => {
  const [base, setBase] = useState(blank);
  const [testImagesHaveLoaded, setTestImagesHaveLoaded] = useState(false);
  const [testImages, setTestImages] = useState([""]);
  // const [areLoading, setAreLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const resized = useResizedImage(base);

  const [filterColor, setFilterColor] = useState("normal");
  const [filterSpeed, setFilterSpeed] = useState("normal");
  const [filterDirection, setFilterDirection] = useState("right");
  const [filterBrightness, setFilterBrightness] = useState(50);
  const [filterContrast, setFilterContrast] = useState(50);
  const [filterSaturation, setFilterSaturation] = useState(50);

  const [forceResetBool, setForceResetBool] = useState(true);

  // @ts-ignore
  // console.log(window.__makeEmojiAllWorkers);

  const resetFilters = () => {
    setFilterColor("normal");
    setFilterSpeed("normal");
    setFilterDirection("right");
    setFilterBrightness(50);
    setFilterContrast(50);
    setFilterSaturation(50);
    setForceResetBool(!forceResetBool);
  };

  const incrementCurrentIndex = () => {
    setCurrentIndex(currentIndex + 1);
  };

  const handleFilesUpload = (e: ChangeEvent) => {
    e.preventDefault();
    setBase("");
    const reader: FileReader = new FileReader();
    reader.onloadend = () => {
      (async () => {
        const result: unknown = reader.result;
        if (typeof result === "string") {
          const fixed: unknown = await rotateExif(result);
          if (typeof fixed === "string") setBase(fixed);
        }
      })();
    };
    const target = e.target as HTMLInputElement;
    const files = target.files as FileList;
    const file: File = files[0];
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    (async () => {
      const uris = await Promise.all(
        testImageData.map(({ url }) => getImageDataUri(`/src/assets/${url}`))
      );
      setTestImages(uris);
      setBase(uris[0]);
      setTestImagesHaveLoaded(true);
    })();
  }, []);

  useEffect(() => {
    // @ts-ignore;
    window.__makeEmojiCurrentSrc = resized;
  }, [resized]);

  useEffect(() => {
    // setAreLoading(true);
    setCurrentIndex(0);
    terminateAllWorkers();
  }, [
    base,
    filterColor,
    filterSpeed,
    filterDirection,
    filterBrightness,
    filterContrast,
    filterSaturation,
    forceResetBool
  ]);

  useEffect(() => {
    if (currentIndex < emojiStyles.length - 1) return;
    // setAreLoading(false);
  }, [currentIndex]);

  return (
    <>
      <main>
        <nav>
          <a className="nav-link" href="/">
            Home
          </a>
          <a className="nav-link" href="/blog">
            Blog
          </a>
          <a className="nav-link" href="https://x.com/makeemoji">
            Follow on X / Twitter
          </a>
        </nav>

        <EditArea>
          <Header data-test="h1">
            <img
              className="m-logo"
              src="/src/assets/m-logo-santa.png"
              alt="MakeEmoji"
            />
          </Header>

          <Subheader>
            <span style={{ fontWeight: "300", fontSize: "22px" }}>
              The{" "}
              <span style={{ fontWeight: "800" }}>
                world's fastest and most popular way
              </span>{" "}
              to create <span className="element1">✨</span>
              <span style={{ fontWeight: "800" }}>animated</span>
              <span className="element2">✨</span>
              custom emotes and emoji GIFs for{" "}
              <span style={{ fontWeight: "800" }}>
                Twitch, Slack, Discord,
              </span>{" "}
              and more!
            </span>
          </Subheader>

          <div>
            <p
              style={{
                textAlign: "center",
                fontSize: "16px",
                color: "#666576",
                maxWidth: "80vw",
                margin: "0 auto 10px"
              }}
            >
              Used by{" "}
              <span style={{ fontWeight: "800" }}>
                tens of thousands of creators
              </span>{" "}
              worldwide and teams at{" "}
              <span style={{ fontWeight: "800" }}>top organizations</span>
            </p>

            <div className="scroll-container">
              <ul className="scroller">
                {imageFilenames.map((filename, i) => (
                  <li
                    key={i}
                    style={{
                      listStyle: "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <img
                      style={{
                        mixBlendMode: "multiply",
                        width: "auto",
                        maxWidth: "80px",
                        height: "40px",
                        objectFit: "contain",
                        objectPosition: "center",
                        padding: "0 15px"
                      }}
                      src={`/src/assets/customers/${filename}`}
                      alt={filename}
                    />
                  </li>
                ))}
                {imageFilenames.map((filename, i) => (
                  <li
                    key={i}
                    style={{
                      listStyle: "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <img
                      style={{
                        mixBlendMode: "multiply",
                        width: "auto",
                        maxWidth: "80px",
                        height: "40px",
                        objectFit: "contain",
                        objectPosition: "center",
                        padding: "15px"
                      }}
                      src={`/src/assets/customers/${filename}`}
                      alt={filename}
                    />
                  </li>
                ))}
                {imageFilenames.map((filename, i) => (
                  <li
                    key={i}
                    style={{
                      listStyle: "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <img
                      style={{
                        mixBlendMode: "multiply",
                        width: "auto",
                        maxWidth: "80px",
                        height: "40px",
                        objectFit: "contain",
                        objectPosition: "center",
                        padding: "15px"
                      }}
                      src={`/src/assets/customers/${filename}`}
                      alt={filename}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/*<!-- Ezoic - under_page_title - under_page_title -->*/}
          <div id="ezoic-pub-ad-placeholder-103"></div>
          {/*<!-- End Ezoic - under_page_title - under_page_title -->*/}

          <EditorArea areLoading={false}>
            <EditorWrap>
              <div id="try">
                <Text>Try it out with these images...</Text>

                <TestImageArea>
                  {testImages.map((src, i) => (
                    <TestImageLink
                      key={i}
                      id={`#test-${i}`}
                      data-tag={`test_btn_${testImageData[i].url}`}
                      tabIndex={0}
                      onClick={() => {
                        setBase(src);
                        // @ts-ignore
                        window.gtag("event", "click", {
                          event_category: "TestImages",
                          event_label: `ti_${testImageData[i].alt
                            .toLowerCase()
                            .replace(/ /g, "_")}`
                        });
                      }}
                    >
                      <img
                        src={src}
                        alt={`Click to make emojis with: ${testImageData[i].alt}`}
                      />
                    </TestImageLink>
                  ))}
                </TestImageArea>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <Text>...or upload your own:</Text>

                <ImageInput
                  aria-label="Upload an image to make emojis"
                  accept="image/png,image/gif,image/jpeg"
                  type="file"
                  onChange={(e: ChangeEvent) => {
                    setBase("");
                    handleFilesUpload(e);
                  }}
                />

                <Text>
                  <div
                    style={{
                      margin: "0 auto",
                      maxWidth: "420px",
                      padding: "10px",
                      fontSize: "12px",
                      borderRadius: "12px",
                      backgroundColor: "rgba(249, 251, 253, 0.75)"
                    }}
                  >
                    <strong>PROTIP:</strong> MakeEmoji can make
                    transparent-background GIFs if you use a transparent PNG
                    file as your source image! Try using{" "}
                    <a
                      href="https://remove.bg"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      remove.bg
                    </a>{" "}
                    to make transparent PNG images.
                  </div>
                </Text>

                <Text>Selected image:</Text>

                <UploadedImage src={resized} alt="" />
              </div>
            </EditorWrap>

            <LoadingText areLoading={false}>Creating emojis...</LoadingText>
          </EditorArea>

          <div className="filter-area">
            <div className="filter-area-basic">
              <button onClick={resetFilters}>Reset All Filters</button>

              <select
                value={filterColor}
                onChange={e => setFilterColor(e.target.value)}
              >
                <option value="normal">⬜ Colors: Normal</option>
                <option value="rainbow">🌈 Colors: Rainbow</option>
                <option value="flat-rainbow">🏳️‍🌈 Colors: Intense Rainbow</option>
                <option value="red">🟥 Colors: Red</option>
                <option value="orange">🟧 Colors: Orange</option>
                <option value="yellow">🟨 Colors: Yellow</option>
                <option value="green">🟩 Colors: Green</option>
                <option value="blue">🟦 Colors: Blue</option>
                <option value="purple">🟪 Colors: Purple</option>
              </select>

              <select
                value={filterSpeed}
                onChange={e => setFilterSpeed(e.target.value)}
              >
                <option value="slow">⏳ Speed: Slow</option>
                <option value="normal">🕗 Speed: Normal</option>
                <option value="fast">🚨 Speed: Fast</option>
              </select>

              <select
                value={filterDirection}
                onChange={e => setFilterDirection(e.target.value)}
              >
                <option value="right">➡️ Direction: Right</option>
                <option value="down">⬇️ Direction: Down</option>
                <option value="left">⬅️ Direction: Left</option>
                <option value="up">⬆️ Direction: Up</option>
              </select>
            </div>
            <div className="filter-area-advanced">
              <details>
                <summary>Advanced Filters</summary>
                <div className="filter-area-advanced-inner">
                  <div className="filter-area-filter">
                    <label htmlFor="brightness">Brightness</label>
                    <div className="filter-area-input">
                      <input
                        type="range"
                        id="brightness"
                        name="brightness"
                        min="0"
                        max="100"
                        value={filterBrightness}
                        step="10"
                        onChange={e =>
                          setFilterBrightness(parseFloat(e.target.value))
                        }
                      />
                      <p>{filterBrightness}</p>
                    </div>
                  </div>
                  <div className="filter-area-filter">
                    <label htmlFor="contrast">Contrast</label>
                    <div className="filter-area-input">
                      <input
                        type="range"
                        id="contrast"
                        name="contrast"
                        min="0"
                        max="100"
                        value={filterContrast}
                        step="10"
                        onChange={e =>
                          setFilterContrast(parseFloat(e.target.value))
                        }
                      />
                      <p>{filterContrast}</p>
                    </div>
                  </div>
                  <div className="filter-area-filter">
                    <label htmlFor="saturation">Saturation</label>
                    <div className="filter-area-input">
                      <input
                        type="range"
                        id="saturation"
                        name="saturation"
                        min="0"
                        max="100"
                        value={filterSaturation}
                        step="10"
                        onChange={e =>
                          setFilterSaturation(parseFloat(e.target.value))
                        }
                      />
                      <p>{filterSaturation}</p>
                    </div>
                  </div>
                </div>
              </details>
            </div>
          </div>
        </EditArea>

        {/*<!-- Ezoic - mid_content - mid_content -->*/}
        <div id="ezoic-pub-ad-placeholder-104"> </div>
        {/*<!-- End Ezoic - mid_content - mid_content -->*/}

        <Text size={14}> Click to download:</Text>

        {base && testImagesHaveLoaded && (
          <EmojiArea>
            <Suspense fallback={<div />}>
              {emojiStyles.map(([name, transformation]: [string, any], i) => {
                const interval = emojiSpeeds[name][filterSpeed];
                if (typeof transformation != "string") {
                  transformation = transformation[filterDirection];
                }
                return (
                  <EmojiPanel
                    key={name}
                    index={i}
                    incrementCurrentIndex={incrementCurrentIndex}
                    currentIndex={currentIndex}
                    img={resized}
                    name={name as string}
                    filterColor={filterColor}
                    filterBrightness={filterBrightness}
                    filterContrast={filterContrast}
                    filterSaturation={filterSaturation}
                    filterDirection={filterDirection}
                    transformation={transformation as any}
                    interval={interval as number}
                  />
                );
              })}
            </Suspense>
          </EmojiArea>
        )}

        {/*<!-- Ezoic - longer_content - longer_content -->*/}
        <div id="ezoic-pub-ad-placeholder-107"> </div>
        {/*<!-- End Ezoic - longer_content - longer_content -->*/}

        <EmailArea>
          <Text size={18}>
            Join{" "}
            <span style={{ fontWeight: "800" }}>
              twelve thousand subscribers
            </span>{" "}
            and get updated when we add 🆕 emoji styles:
          </Text>

          <div>
            <iframe
              src="https://makeemoji.substack.com/embed"
              width="480"
              height="200"
              frameBorder="0"
              scrolling="no"
            ></iframe>
          </div>

          <Text size={14}>
            Very occasional emails, only when we add new emoji styles.
            <br />
            No spam or bullshit ever.
          </Text>
        </EmailArea>

        <Text size={16} paddingBottom={true}>
          Bug? Feature request? Tweet us at:{" "}
          <a
            href="https://x.com/makeemoji"
            target="_blank"
            rel="noreferrer noopener"
          >
            @makeemoji
          </a>
        </Text>

        <p
          style={{
            textAlign: "center",
            fontSize: "16px",
            color: "#666576",
            maxWidth: "80vw",
            margin: "0 auto 10px"
          }}
        >
          <span style={{ fontWeight: "800" }}>Learn how</span> to use MakeEmoji
        </p>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            flexWrap: "wrap",
            margin: "0 auto 30px"
          }}
        >
          <div
            style={{
              borderRadius: "12px",
              backgroundColor: "rgba(55, 47, 186, 0.05)",
              padding: "15px"
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontSize: "12px",
                color: "#000",
                maxWidth: "80vw",
                margin: "0 auto 10px"
              }}
            >
              Make <span style={{ fontWeight: "800" }}>Slack emojis</span> for
              your coworkers
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
                flexWrap: "wrap"
              }}
            >
              <img
                src="/src/assets/samples/slack-jam.gif"
                alt=""
                style={{ width: "32px", height: "32px" }}
              />

              <img
                src="/src/assets/samples/slack-tilt.gif"
                alt=""
                style={{ width: "32px", height: "32px" }}
              />

              <img
                src="/src/assets/samples/slack-party.gif"
                alt=""
                style={{ width: "32px", height: "32px" }}
              />
            </div>
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <a
                href="#try"
                className="hover-fade"
                onClick={() => {
                  setTimeout(() => {
                    window.location.hash = "";
                  }, 1500);
                }}
                style={{
                  display: "inline-block",
                  backgroundColor: "#3731BA",
                  color: "white",
                  padding: "5px 12px",
                  fontSize: "12px",
                  borderRadius: "8px",
                  textDecoration: "none"
                }}
              >
                Get started
              </a>
            </div>
          </div>

          <div
            style={{
              borderRadius: "12px",
              backgroundColor: "rgba(55, 47, 186, 0.05)",
              padding: "15px"
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontSize: "12px",
                color: "#000",
                maxWidth: "80vw",
                margin: "0 auto 10px"
              }}
            >
              Make <span style={{ fontWeight: "800" }}>Twitch emotes</span> for
              your channel
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
                flexWrap: "wrap"
              }}
            >
              <img
                src="/src/assets/samples/twitch-flip.gif"
                alt=""
                style={{ width: "32px", height: "32px" }}
              />

              <img
                src="/src/assets/samples/twitch-pet.gif"
                alt=""
                style={{ width: "32px", height: "32px" }}
              />

              <img
                src="/src/assets/samples/twitch-mega-bounce.gif"
                alt=""
                style={{ width: "32px", height: "32px" }}
              />
            </div>
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <a
                href="/blog/how-to-make-animated-twitch-custom-emotes-for-free/"
                className="hover-fade"
                style={{
                  display: "inline-block",
                  backgroundColor: "#3731BA",
                  color: "white",
                  padding: "5px 12px",
                  fontSize: "12px",
                  borderRadius: "8px",
                  textDecoration: "none"
                }}
              >
                Learn how
              </a>
            </div>
          </div>

          <div
            style={{
              borderRadius: "12px",
              backgroundColor: "rgba(55, 47, 186, 0.05)",
              padding: "15px"
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontSize: "12px",
                color: "#000",
                maxWidth: "80vw",
                margin: "0 auto 10px"
              }}
            >
              Make <span style={{ fontWeight: "800" }}>Discord emojis</span> for
              your server
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
                flexWrap: "wrap"
              }}
            >
              <img
                src="/src/assets/samples/discord-shake.gif"
                alt=""
                style={{ width: "32px", height: "32px" }}
              />

              <img
                src="/src/assets/samples/discord-jam.gif"
                alt=""
                style={{ width: "32px", height: "32px" }}
              />

              <img
                src="/src/assets/samples/discord-zoom-close.gif"
                alt=""
                style={{ width: "32px", height: "32px" }}
              />
            </div>
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <a
                href="/blog/how-to-make-free-animated-custom-discord-emotes/"
                className="hover-fade"
                style={{
                  display: "inline-block",
                  backgroundColor: "#3731BA",
                  color: "white",
                  padding: "5px 12px",
                  fontSize: "12px",
                  borderRadius: "8px",
                  textDecoration: "none"
                }}
              >
                Learn how
              </a>
            </div>
          </div>
        </div>

        <div
          style={{
            margin: "80px auto",
            padding: "0 20px",
            maxWidth: "650px",
            opacity: "0.85"
          }}
        >
          <h2 style={{ fontSize: "24px" }}>
            Creating Custom Animated Emojis and Emotes with MakeEmoji
          </h2>
          <h3 style={{ marginBottom: "16px" }}>Introduction</h3>
          <p style={{ textAlign: "justify", fontStyle: "italic" }}>
            In today's digital landscape,{" "}
            <strong>custom animated emojis, emotes, and stickers</strong> have
            become essential tools for enhancing online communication and
            engagement. Whether you're a Twitch streamer aiming to create unique
            subscriber emotes, a Discord server admin looking to add exclusive
            animated emojis, or a business wanting to bring life to your Slack
            channels, <strong>MakeEmoji</strong> is here to help. As the leading
            online platform for designing and creating personalized animated
            GIFs, MakeEmoji empowers users to craft high-quality, eye-catching
            emotes and stickers with ease.
          </p>

          <h3 style={{ marginTop: "24px", marginBottom: "16px" }}>
            Unlocking the Potential of Custom Twitch Emotes, Discord Custom
            Emojis, and Slack Custom Emojis
          </h3>
          <p>
            <strong>Twitch Custom Emotes:</strong> Elevate Your Streaming
            Experience
          </p>
          <p style={{ textAlign: "justify" }}>
            As a Twitch streamer, custom emotes are essential for building your
            brand and engaging with your audience. Subscribers love showing off
            their loyalty with exclusive emotes, and MakeEmoji makes it easy to
            create high-quality, animated subscriber emotes that perfectly
            represent your channel's personality.
          </p>
          <h3 style={{ marginTop: "24px", marginBottom: "16px" }}>
            Discord Custom Animated Emojis: Bring Your Server to Life
          </h3>
          <p style={{ textAlign: "justify" }}>
            Discord servers are all about community, and custom animated emojis
            are a great way to make your server feel unique and engaging. With
            MakeEmoji, you can create a wide range of custom Discord emotes,
            from animated reactions to server-specific inside jokes, that will
            keep your members coming back for more.
          </p>
          <h3 style={{ marginTop: "24px", marginBottom: "16px" }}>
            Slack Custom Emojis: Boost Team Morale and Engagement
          </h3>
          <p style={{ textAlign: "justify" }}>
            For businesses using Slack, custom emojis can be a game-changer for
            team communication and morale. MakeEmoji allows you to create
            branded, animated Slack emoji that inject personality and humor into
            daily conversations, making work feel more fun and connected.
          </p>
          <h3 style={{ marginTop: "24px", marginBottom: "16px" }}>
            Step-by-Step Guide: How to Make Custom Animated Emojis and Emotes
            with MakeEmoji
          </h3>
          <p style={{ fontWeight: "bold" }}>
            Creating custom animated emojis and emotes with MakeEmoji is a
            simple and intuitive process. Follow these steps to bring your ideas
            to life:
          </p>
          <p>
            <strong>1. Select or Upload Your Image</strong>
          </p>
          <p style={{ textAlign: "justify" }}>
            Choose from MakeEmoji's extensive library of popular meme templates,
            emojis, and images, or upload your own PNG or GIF file. MakeEmoji
            supports transparent backgrounds for seamless integration into any
            platform.
          </p>
          <p>
            <strong>2. Customize Your Animation Style</strong>
          </p>
          <p style={{ textAlign: "justify" }}>
            Explore MakeEmoji's wide range of animation styles, including
            bounce, shake, spin, zoom, and more. Adjust the speed, direction,
            and intensity to achieve the perfect look and feel for your custom
            emoji or emote.
          </p>
          <p>
            <strong>3. Fine-Tune Colors, Filters, and Effects</strong>
          </p>
          <p style={{ textAlign: "justify" }}>
            Use MakeEmoji's advanced color and filter options to refine your
            creation. Adjust brightness, contrast, saturation, and more to
            ensure your animated emoji looks exactly how you want it.
          </p>
          <p>
            <strong>4. Download and Share Your Custom Creation</strong>
          </p>
          <p style={{ textAlign: "justify" }}>
            Once you're satisfied with your custom animated emoji or emote
            download the individual GIF files or the entire set with just one
            click. Easily share your creations with your Twitch subscribers,
            Discord server members, or Slack teammates and watch engagement
            soar!
          </p>
          <h3 style={{ marginTop: "24px", marginBottom: "16px" }}>
            Explore the World of Animated Emoji Styles and Effects
          </h3>
          <p style={{ textAlign: "justify" }}>
            MakeEmoji offers an unparalleled selection of animation styles and
            effects to help you create the perfect custom emojis and emotes for
            any occasion. Some of our most popular categories include:
          </p>
          <ul style={{ marginLeft: "20px" }}>
            <li>
              <strong>Celebrations and Party Vibes:</strong> Spread joy with our
              collection of festive animations, like party blob, party parrot,
              jamming cat, and more.
            </li>
            <li>
              <strong>Emotions and Reactions:</strong> Convey a wide range of
              emotions with our expressive animations, from excited and
              intensified to crying and deal-with-it.
            </li>
            <li>
              <strong>Memes and Inside Jokes:</strong> Bring your favorite memes
              and inside jokes to life with our extensive library of popular
              templates, including the doge meme, stonks meme, thonking meme,
              and more.
            </li>
            <li>
              <strong>Dynamic Actions and Motions:</strong> Add a touch of
              dynamism to your custom emojis with our collection of
              action-packed animations, such as spinning, petting, zooming, and
              more.
            </li>
          </ul>
          <h3 style={{ marginTop: "24px", marginBottom: "16px" }}>
            Why MakeEmoji is the Go-To Platform for Custom Animated Emojis and
            Emotes
          </h3>
          <p style={{ textAlign: "justify" }}>
            MakeEmoji has become the trusted choice for tens of thousands of
            creators, influencers, and businesses worldwide due to its:
          </p>
          <ul style={{ marginLeft: "20px" }}>
            <li>
              <strong>User-Friendly Interface:</strong> Our intuitive platform
              makes it easy for anyone to create stunning animated emojis and
              emotes, regardless of design experience.
            </li>
            <li>
              <strong>Unmatched Customization:</strong> With an extensive range
              of animation styles, color options, filters, and effects,
              MakeEmoji empowers you to create truly unique, on-brand emojis and
              emotes.
            </li>
            <li>
              <strong>High-Quality Output:</strong> MakeEmoji produces
              high-resolution, smooth-looping GIFs that look fantastic at any
              size and on any platform.
            </li>
            <li>
              <strong>Universal Compatibility:</strong> Our custom animated
              emojis, emotes, and stickers work seamlessly with all major
              platforms, including Twitch, Discord, Slack, and more.
            </li>
            <li>
              <strong>Completely Free:</strong> MakeEmoji is entirely free to
              use, with no hidden costs or subscriptions. Create as many custom
              emojis, emotes, and stickers as you need!
            </li>
          </ul>
          <h3 style={{ marginTop: "24px", marginBottom: "16px" }}>
            Elevate Your Online Presence with MakeEmoji's Custom Animated Emojis
            and Emotes
          </h3>
          <p style={{ textAlign: "justify", fontStyle: "italic" }}>
            Ready to take your Twitch channel, Discord server, or Slack
            workspace to the next level? Visit <strong>MakeEmoji.com</strong>{" "}
            and start creating custom animated emojis and emotes today! Our
            user-friendly platform and extensive library of styles, templates,
            and effects make it easy to craft engaging, memorable, and on-brand
            emojis in minutes.
          </p>
          <p style={{ textAlign: "justify" }}>
            Subscribe to our newsletter to stay up-to-date on the latest
            features, templates, and tips for designing the ultimate custom
            emojis and emotes. Join the growing MakeEmoji community and unlock
            the power of personalized, animated content for your online
            presence!
          </p>
          <h3 style={{ marginTop: "24px", marginBottom: "16px" }}>
            Frequently Asked Questions About Creating Custom Twitch Emotes,
            Discord Emojis, and Slack Emojis
          </h3>
          <p>
            <strong>
              1. Can I use MakeEmoji to create static custom emojis, emotes, and
              stickers?
            </strong>
          </p>
          <p style={{ textAlign: "justify" }}>
            Absolutely! While MakeEmoji specializes in animated content, you can
            easily create static custom emojis and emotes by selecting the
            "none" animation style.
          </p>
          <p>
            <strong>
              2. How do I add my custom Twitch emotes, Discord emojis, or Slack
              stickers to my account?
            </strong>
          </p>
          <p style={{ textAlign: "justify" }}>
            Simply download your custom creations from MakeEmoji and follow the
            upload instructions for each platform. For Twitch, use the Emote
            Manager in your Creator Dashboard. On Discord, use the Server
            Settings to upload custom emojis. And for Slack, add custom emoji
            through the Customize Your Workspace settings.
          </p>
          <p>
            <strong>
              3. Is there a limit to how many custom emojis, emotes, or stickers
              I can create with MakeEmoji?
            </strong>
          </p>
          <p style={{ textAlign: "justify" }}>
            No! MakeEmoji allows you to create an unlimited number of custom
            animated emojis and emotes for free.
          </p>
          <p>
            <strong>
              4. Do I need to credit MakeEmoji when using custom emojis, emotes,
              or stickers?
            </strong>
          </p>
          <p style={{ textAlign: "justify" }}>
            Nope! You're free to use your custom creations however you like,
            without any need for attribution. You own the rights to custom
            emojis and emotes you create with MakeEmoji.
          </p>
          <p style={{ textAlign: "justify", fontWeight: "bold" }}>
            Start creating amazing custom animated emojis and emotes for Twitch,
            Discord, Slack, and more with MakeEmoji today! Unlock the power of
            personalized, engaging content and take your online presence to new
            heights.
          </p>
        </div>

        <Text size={11} paddingBottom={true}>
          <span
            style={{
              maxWidth: "700px",
              margin: "0 auto",
              opacity: 0.3,
              padding: "0"
            }}
          >
            Not affiliated with or supported by Twitch Interactive, Inc., Slack
            Technologies, Inc., Discord, Inc., 1Password, Adidas, Carta, Chewy,
            Datadog, Flock Safety, Forter, FullStory, GitLab, Hugging Face,
            Kakao, Lotte, McKinsey & Company, MongoDB, Square, Unity
            Technologies, X (formerly Twitter), ZoomInfo, Inngest, Scale AI,
            Checkout.com, or Shopify |{" "}
            <a href="./Privacy.html">Privacy Policy</a>
          </span>
        </Text>
      </main>
    </>
  );
};

export default App;
