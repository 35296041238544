import React, { useState, useEffect } from "react";
import transformations from "./transformations";
import { Panel, Image, Name } from "./styled";
import { makeGif } from "./makeGif";

const blank = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=`;

const postEmojiToNetlify = async base64Image => {
  try {
    const response = await fetch("/.netlify/functions/downloaded", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ image: base64Image })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const message = await response.text();
    console.log(message);
  } catch (error) {
    console.error("Error:", error);
  }
};

const WIDTH = 112;
const HEIGHT = 112;
type TransformationFn = (
  ctx: CanvasRenderingContext2D,
  img: HTMLImageElement,
  i: number,
  filterColor: string,
  filterBrightness: number,
  filterContrast: number,
  filterSaturation: number
) => void;

const transform = async (
  image50: string,
  transformation: keyof typeof transformations,
  i: number,
  filterColor: string,
  filterBrightness: number,
  filterContrast: number,
  filterSaturation: number
) => {
  return new Promise((resolve, reject) => {
    const transformImg = document.createElement("img");
    const returnImg = document.createElement("img");
    const canvas = document.createElement("canvas");
    canvas.width = WIDTH;
    canvas.height = HEIGHT;

    returnImg.onload = async () => {
      resolve(returnImg);
    };

    transformImg.onload = async () => {
      const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
      ctx.imageSmoothingEnabled = false;
      ctx.fillStyle = "rgba(0, 0, 0, 0)";
      ctx.fillRect(0, 0, WIDTH, HEIGHT);
      ctx.clearRect(0, 0, WIDTH, HEIGHT);
      ctx.beginPath();
      const transformationFn: TransformationFn =
        transformations[transformation];
      await transformationFn(
        ctx,
        transformImg,
        i,
        filterColor,
        filterBrightness,
        filterContrast,
        filterSaturation
      );
      returnImg.src = canvas.toDataURL("image/png");
      if (transformImg.parentNode) {
        transformImg.parentNode.removeChild(transformImg);
      }
      if (canvas.parentNode) {
        canvas.parentNode.removeChild(canvas);
      }
    };

    if (image50) transformImg.src = image50;
  });
};

const downloadUri = (uri: string, name: string) => {
  const link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

interface EmojiPanelProps {
  index: number;
  incrementCurrentIndex: () => void;
  currentIndex: number;
  img: string;
  transformation: keyof typeof transformations;
  filterDirection: string;
  filterColor: string;
  filterBrightness: number;
  filterContrast: number;
  filterSaturation: number;
  name: string;
  interval: number;
  frameCount?: number;
}

const EmojiPanel = ({
  index,
  currentIndex,
  incrementCurrentIndex,
  img,
  transformation,
  filterDirection,
  filterColor,
  filterBrightness,
  filterContrast,
  filterSaturation,
  name,
  interval,
  frameCount = 10
}: EmojiPanelProps) => {
  const [gif, setGif] = useState(blank);
  const [loading, setLoading] = useState(false);

  const stillCurrentSrc = () => {
    // @ts-ignore;
    return window.__makeEmojiCurrentSrc === img;
  };

  useEffect(() => {
    if (!stillCurrentSrc) return;

    // const t1 = performance.now();
    if (!(gif === blank) && currentIndex <= index) {
      setGif(blank);
    }
    if (currentIndex !== index || !img) return;
    setLoading(true);
    (async () => {
      const framesArray = [...Array(frameCount)].fill(null);
      const imagePromises = framesArray.map(async (_, i) => {
        return await transform(
          img,
          transformation,
          i,
          filterColor,
          filterBrightness,
          filterContrast,
          filterSaturation
        );
      });
      const images = await Promise.all(imagePromises);

      incrementCurrentIndex();

      if (!stillCurrentSrc) return;
      const nextGif = new makeGif({
        delay: interval
      });

      images.forEach(image => nextGif.addFrame(image));
      nextGif.getBase64GIF(function(image: any) {
        setLoading(false);
        setGif(image);
        nextGif.destroy();
        // console.log(nextGif);
        // const t2 = performance.now();
        // console.log(`Took ${((t2 - t1) / 1000).toFixed(2)}s to generate gif`);
      });
    })();
  }, [img, currentIndex]);

  const isClickable = gif !== blank;

  const handleClick = () => {
    // Send event to Google Analytics
    console.log({
      event_category: "EmojiPanel",
      event_label: name
    });

    try {
      // @ts-ignore
      window.gtag("event", "click", {
        event_category: "EmojiPanel",
        event_label: name
      });
      // HERE
    } catch (e) {
      console.log(e);
    }

    if (isClickable) {
      downloadUri(gif, name);
      postEmojiToNetlify(gif); // add this line
    }
  };

  const isDirectioned = ["scroll", "slide"].includes(name);

  const directionIcon = {
    right: "➡️",
    down: "⬇️",
    left: "⬅️",
    up: "⬆️"
  };

  return (
    <Panel
      onClick={handleClick}
      clickable={isClickable}
      data-tag={`gen_btn_${name}`}
    >
      <Image
        src={gif}
        isLoading={loading}
        alt={`The generated ${name} animated emoji`}
      />
      <Name>:{name}:</Name>

      {isDirectioned && (
        <div className="direction">{directionIcon[filterDirection]}</div>
      )}
    </Panel>
  );
};

export default EmojiPanel;
