const headBob = [
  [0.17, -0.05],
  [0.05, -0.1],
  [-0.05, -0.1],
  [-0.17, -0.05],
  [-0.27, 0], //
  [-0.17, 0.05],
  [-0.05, 0.1],
  [0.05, 0.1],
  [0.17, 0.05],
  [0.27, 0], //
];

const getImg = async (i: number) => {
  const fetched = await fetch(
    `./src/assets/frames/party-parrot/frame_0${i}_delay-0.03s.png`
  );
  const blob: Blob = await fetched.blob();
  const dataUrl = URL.createObjectURL(blob);
  return await new Promise((resolve, reject) => {
    const newImg = new Image();
    newImg.onload = () => resolve(newImg);
    newImg.src = dataUrl;
  });
};

const createPartyParrotFrame = async (
  ctx: CanvasRenderingContext2D,
  img: HTMLImageElement,
  i: number,
  width: number,
  height: number
) => {
  const partyParrotImg = (await getImg(i)) as HTMLImageElement;
  const [headBoxX, headBobY] = headBob[i];
  ctx.translate(width / 2, height / 2);
  ctx.drawImage(partyParrotImg, -width / 2, -height / 2 + 10);
  ctx.drawImage(
    img,
    -width / 2 + width * 0.12 + width * headBoxX,
    -height / 2 + height * 0.15 + height * headBobY + 0.05,
    width * 0.7,
    height * 0.7
  );
};

export default createPartyParrotFrame;
