const headBob = [
  [-0.03, 0],
  [0, -0.01],
  [0.03, 0],
  [0.015, 0.01],
  [0, 0.01],
  [-0.03, 0],
  [0, -0.01],
  [0.03, 0],
  [0.015, 0.01],
  [0, 0.01]
];

const getImg = async (i: number) => {
  const fetched = await fetch(
    `./src/assets/frames/nyan-cat/frame_0${i}_delay-0.07s.png`
  );
  const blob: Blob = await fetched.blob();
  const dataUrl = URL.createObjectURL(blob);
  return await new Promise((resolve, reject) => {
    const newImg = new Image();
    newImg.onload = () => resolve(newImg);
    newImg.src = dataUrl;
  });
};

const createNyanCatFrame = async (
  ctx: CanvasRenderingContext2D,
  img: HTMLImageElement,
  i: number,
  width: number,
  height: number
) => {
  const nyanCatImg = (await getImg(i)) as HTMLImageElement;
  const [headBobX, headBobY] = headBob[i];
  ctx.translate(width / 2, height / 2);
  ctx.drawImage(nyanCatImg, -width / 2 + 2, -height / 2 + 6);
  ctx.drawImage(
    img,
    -width / 2 + width * 0.475 + width * headBobX,
    -height / 2 + height * 0.19 + height * headBobY,
    width * 0.55,
    height * 0.55
  );
};

export default createNyanCatFrame;
