import styled, { css } from "styled-components";

const fontStack = `'Plus Jakarta Sans', sans-serif`;

export const EditArea = styled.div`
  width: calc(100% - 40px);
  max-width: 1000px;
  padding: 30px 20px 20px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

interface EditorAreaProps {
  readonly areLoading: boolean;
}
export const EditorArea = styled.div<EditorAreaProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px auto 30px;
  ${props =>
    props.areLoading &&
    css`
      & > * {
        opacity: 0.1;
        pointer-events: none;
      }
    `}
`;

export const EditorWrap = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

interface LoadingTextProps {
  readonly areLoading: boolean;
}
export const LoadingText = styled.p<LoadingTextProps>`
  display: none;
  font-family: ${fontStack};
  opacity: 1 !important;
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translate(-50%, 0);
  ${props =>
    props.areLoading &&
    css`
      display: initial;
    `}
`;

export const ImageInput = styled.input`
  max-width: 100%;
  outline: 25px solid rgba(55, 47, 186, 0.1);
  background: rgba(55, 47, 186, 0.1);
  margin: 30px auto;
  border-radius: 8px;
`;

export const EmojiArea = styled.div`
  width: calc(100% - 40px);
  max-width: 1000px;
  padding: 20px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

export const EmailArea = styled.div`
  width: calc(100% - 40px);
  max-width: 500px;
  padding: 20px 20px 30px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

export const TestImageArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 10px 10px;
  max-width: 500px;
`;

export const TestImageLink = styled.a`
  width: 45px;
  height: 45px;
  margin: 8px;
  cursor: pointer;
  border: 2px solid rgb(249, 251, 253);
  border-radius: 3px;
  &:hover {
    border: 2px solid rgba(57, 47, 193, 1);
  }
  & img {
    width: 100%;
    height: 100%;
  }
`;

export const Header = styled.h1`
  font-family: ${fontStack};
  text-align: center;
  font-size: 35px;
  color: #2b2b2b;
  margin: 0 0 5px;
  @media (max-width: 450px) {
    font-size: 25px;
  }
`;

export const Subheader = styled.h2`
  font-family: ${fontStack};
  font-size: 18px;
  text-align: center;
  max-width: 800px;
  color: #2b2b2b;
  margin: 0 0 30px;
  @media (max-width: 450px) {
    font-size: 18px;
  }
`;

interface TextProps {
  readonly size?: number;
  readonly paddingTop?: boolean;
  readonly paddingBottom?: boolean;
  readonly highlight?: boolean;
}
export const Text = styled.p<TextProps>`
  font-family: ${fontStack};
  font-size: 15px;
  text-align: center;
  color: #2b2b2b;
  margin: 8px;
  ${props =>
    props.paddingBottom &&
    css`
      padding-bottom: 30px;
    `}
  ${props =>
    props.paddingTop &&
    css`
      padding-top: 30px;
    `}
  ${props =>
    props.size &&
    css`
      font-size: ${props.size}px;
    `}
  ${props =>
    props.highlight &&
    css`
      background-color: rgb(249, 251, 253);
      padding: 10px 18px;
      margin: 20px 0 0;
      border-radius: 8px;
    `}
`;

export const UploadedImage = styled.img`
  width: 112px;
  height: 112px;
  outline: 1px solid rgb(249, 251, 253);
  border-radius: 8px;
  outline: 25px solid rgba(55, 47, 186, 0.05);
  background: rgba(55, 47, 186, 0.05);
  margin: 40px auto;
`;

export const Spaces = styled.span`
  @media (max-width: 450px) {
    display: block;
    padding-bottom: 10px;
  }
`;
