const gunFrames = [
  3,
  3,
  2,
  2,
  1,
  1,
  1,
  1,
  1,
  1,
];

const getImg = async (i: number) => {
  const fetched = await fetch(
    `./src/assets/frames/gun/gun-${gunFrames[i]}.png`
  );
  const blob: Blob = await fetched.blob();
  const dataUrl = URL.createObjectURL(blob);
  return await new Promise((resolve, reject) => {
    const newImg = new Image();
    newImg.onload = () => resolve(newImg);
    newImg.src = dataUrl;
  });
};

const createGunFrame = async (
  ctx: CanvasRenderingContext2D,
  img: HTMLImageElement,
  i: number,
  width: number,
  height: number
) => {
  const gunImg = (await getImg(i)) as HTMLImageElement;
  ctx.translate(width / 2, height / 2);
  ctx.drawImage(img, -width / 2, -height / 2);
  ctx.drawImage(gunImg, -width / 2, -height / 2 + 10, width, height);
};

export default createGunFrame;
