const headBob = [
  [-0.05, -0.1],
  [-0.17, -0.05],
  [-0.27, 0], //
  [-0.17, 0.05],
  [-0.05, 0.1],
  [0.05, 0.1],
  [0.17, 0.05],
  [0.27, 0], //
  [0.17, -0.05],
  [0.05, -0.1],
];

const frames = [
  ['00', 9],
  ['02', 9],
  ['03', 2],
  ['04', 9],
  ['06', 9],
  ['07', 2],
  ['08', 9],
  ['10', 9],
  ['12', 9],
  ['13', 2],
];

const getImg = async (i: number) => {
  const fetched = await fetch(
    `./src/assets/frames/jammies/frame_${frames[i][0]}_delay-0.0${frames[i][1]}s.png`
  );
  const blob: Blob = await fetched.blob();
  const dataUrl = URL.createObjectURL(blob);
  return await new Promise((resolve, reject) => {
    const newImg = new Image();
    newImg.onload = () => resolve(newImg);
    newImg.src = dataUrl;
  });
};

const createJammiesFrame = async (
  ctx: CanvasRenderingContext2D,
  img: HTMLImageElement,
  i: number,
  width: number,
  height: number
) => {
  const jammiesImg = (await getImg(i)) as HTMLImageElement;
  const [headBoxX, headBobY] = headBob[i];
  ctx.translate(width / 2, height / 2);
  ctx.drawImage(jammiesImg, -width / 2, -height / 2 + 10);
  ctx.drawImage(
    img,
    -width / 2 + width * 0.12 + (width * headBoxX / 6) + 12,
    -height / 2 + height * 0.15 + ((height * headBobY + 0.05) / 6) - 23,
    width * 0.55,
    height * 0.55
  );
};

export default createJammiesFrame;
