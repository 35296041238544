import contrastFilter from "../filters/contrast";

const getImg = async (i: number) => {
  const fetched = await fetch(`./src/assets/frames/deal-with-it/${i + 1}.png`);
  const blob: Blob = await fetched.blob();
  const dataUrl = URL.createObjectURL(blob);
  return await new Promise((resolve, reject) => {
    const newImg = new Image();
    newImg.onload = () => resolve(newImg);
    newImg.src = dataUrl;
  });
};

const createDealWithItFrame = async (
  ctx: CanvasRenderingContext2D,
  img: HTMLImageElement,
  i: number,
  width: number,
  height: number,
  contrast: number
) => {
  const dealWithItImg = (await getImg(i)) as HTMLImageElement;
  ctx.translate(width / 2, height / 2);
  ctx.drawImage(img, -width / 2, -height / 2);
  ctx.drawImage(
    dealWithItImg,
    -width / 2 - width * 0.22,
    -height / 2 - height * 0.05,
    width * 1.2,
    height * 1.2
  );
};

export default createDealWithItFrame;
