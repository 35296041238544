const brightness = (context: CanvasRenderingContext2D, amount = 1) => {
    amount = amount / 100;
    if (amount === 1) return context;
    if (amount < 0) amount = 0;

    const { height, width } = context.canvas;
    const imageData = context.getImageData(0, 0, width, height);
    const { data } = imageData;
    const { length } = data;

    // in rgba world, every
    // n * 4 + 0 is red,
    // n * 4 + 1 green and
    // n * 4 + 2 is blue
    // the fourth can be skipped as it's the alpha channel

    for (let i = 0; i < length; i += 4) {
        data[i + 0] *= amount;
        data[i + 1] *= amount;
        data[i + 2] *= amount;
    }

    context.putImageData(imageData, 0, 0);
    return context;
};

export default brightness;