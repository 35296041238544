import contrastFilter from "../filters/contrast";

const getImg = async (i: number) => {
  const fetched = await fetch(`./src/assets/frames/fire/${i + 1}.png`);
  const blob: Blob = await fetched.blob();
  const dataUrl = URL.createObjectURL(blob);
  return await new Promise((resolve, reject) => {
    const newImg = new Image();
    newImg.onload = () => resolve(newImg);
    newImg.src = dataUrl;
  });
};

const createBurnFrame = async (
  ctx: CanvasRenderingContext2D,
  img: HTMLImageElement,
  i: number,
  width: number,
  height: number,
  contrast: number
) => {
  const skipFire = i < 6;
  let fireImg;
  if (skipFire) {
    fireImg = (await getImg(i)) as HTMLImageElement;
  }
  ctx.translate(width / 2, height / 2);
  ctx.drawImage(img, -width / 2, -height / 2);
  if (skipFire) {
    ctx.drawImage(fireImg as HTMLImageElement, -width / 2, -height / 2 + 12);
  }
};

export default createBurnFrame;
